<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" class="elevation-1" >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title v-show="(title)">
                    <v-alert class="mb-0" border="left" dense colored-border type="info">{{title}}</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn v-if="isAuthorized( 'Contact', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openContact(0)"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>        
        <template v-slot:[`item.eve_typ_id`]="{ item }">
            <v-chip label outlined :color="typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_color">{{ typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.eve_sta_id`]="{ item }">
            <v-chip label outlined color="green">{{ stateState['GEN'][item.eve_sta_id].sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.eve_date_start`]="{ item }">
            <v-chip label outlined>{{ locDateFormat.toLocale( item.eve_date_start ) }}</v-chip>
        </template>
        <template v-slot:[`item.eve_id`]="{ item }">
            <v-icon left color="orange" v-if="isAuthorized( 'Contact', 'update' )" @click="openContact(item.eve_id)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getContact )" color="error" icon="mdi-alert">
            Aucun contact
        </v-alert>
        </template>
    </v-data-table>
    <v-dialog v-model="modalContact" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Contact</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalContact', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-contact class="elevation-2" 
                v-if="modalContact" :toast="toast" :switchModal="switchModal" :prefill="{ name: search }" :addToTeam="addToTeam">
            </edit-contact>
        </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalSearchContact" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Contact</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalSearchContact', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <auto-multi v-if="!loading.getItem" :title="'Contact'" ressource="contacts" :listObject.sync="autoComplete.ctt_id" :toast="toast"
                :listParent.sync="autoCompleteSource.ctt" 
                :fields="{ 
                    index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false, notfound: 'Créer un contact',
                    notfoundClick: true
                }"
                :notfoundClick="openContactEdit">
            </auto-multi>
            <v-btn class="mr-2" color="success" :loading="saveProgress" @click.native="addToTeam({ctt_id: autoComplete.ctt_id})" :disabled="saveProgress">
            ok
            </v-btn>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import editContact  from '../components/Edit_contact.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'tab_contact',
    props: ['toast', 'table_id', 'ctr_xxx_id', 'title', 'typfilter', 'routeState'],
    data: function () {
        return {
            loading: { uploadBtn: false, rebuildBtn: false, getContact: false },         // Enregistrement en cours...
            search: '',
            saveProgress: false,         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            headers: [
            { text: 'Description', align: 'left', value: 'ctt_id'},
            { text: 'Date', align: 'left', value: 'ctt_firstname'},
            { text: 'Type', align: 'left', value: 'ctt_phone'},
            { text: 'Statut', value: 'ctt_middlename' },
            { text: 'Action', value: 'eve_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalContact : false,
            modalSearchContact: false,
            prv_id: 0,
            eve_id: 0,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', ctr_table_id: this.table_id, ctr_xxx_id  : this.ctr_xxx_id },
            modalPagin : true,
            autoComplete: {
                ctt_id: null,
            },
            autoCompleteSource:{
                ctt: [],
            },
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted: function (){
        this.getItems()
    },
    watch: { },
    methods: {
       getItems(){
            return new Promise( (resolve, reject) => {
                //var searchCriteria = '?per_page=false'
                //searchCriteria    += '&ctr_xxx_id=' +  this.ctr_xxx_id + '&ctr_table_id=' + this.table_id
                //searchCriteria    += ( this.typfilter ) ? '&ctt_typ_id=%' + this.typfilter + '%' : ''
                this.items = []
                this.loading.getDocument = true
                this.$http.get( '/contacts/?extended=withentity&enc_ent_id=' + this.ctr_xxx_id ).then( (response) => {
                    if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                        this.pagination.totalItems  = parseInt(response.data.meta.pagination.total)
                    } else {
                        this.pagination.totalItems = 1
                    }
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.items = response.data.data
                    }
                    this.loading.getContact = false
                    resolve('resolved')
                })
                .catch( (error) => {
                    this.toast({ color: 'warning', text: error })
                    reject(error)
                })
            })
        },
        openContact(id){
            this.eveInfo.eve_id = id
            this.switchModal('modalSearchContact', 1)
        },
        openContactEdit(){
            this.switchModal('modalSearchContact', 0)
            this.switchModal('modalContact', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalContact', 1)
            }
        },
        addToTeam(contact){
            this.$http.put( 'entities/' + this.ctr_xxx_id + '/contact', contact ).then( () => {
                this.getItems()
                this.switchModal('modalSearchContact', 0)
            })
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalContact'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){ 
                this.getItems()
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti,
        'edit-contact'   : editContact
    }
}
</script>