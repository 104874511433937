<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" :to="'/' + $route.name + '/0'">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1"></v-col>
            </v-row>
        </template>
        <template v-slot:[`item.cat_updated_at`]="{ item }">
            <v-chip small outlined label light :color="'grey'">
            {{ locDateFormat.formatLocale(item.cat_updated_at, 'fr') }}
            </v-chip>        
        </template>    
        <template v-slot:[`item.cat_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/category/' + item.cat_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'categories',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Code',                align: 'left', value: 'cat_code'},
            { text: 'Nom',                 align: 'left', value: 'cat_name'},
            { text: 'Modification',        align: 'right',value: 'cat_updated_at' },
            { text: 'Action',              align: 'right', value: 'cat_id'},
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            equStatus: {
                'GEN01' : 'Actif',
                'GEN02' : 'Inactif',
                'GEN03' : 'Terminé',
                'GEN04' : 'Archivé',
                'GEN05' : 'Supprimé',
            },
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('categories') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/categories?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('categories', this.search)
        },
    },
    components: {
        
    }
}
</script>