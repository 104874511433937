import Vue           from 'vue'
import router        from './services/router'
import App           from './App.vue'
import Storages      from 'js-storage'
import vuetify       from './plugins/vuetify';
//import AuthPlugin    from './services/auth'
import ApiService    from './services/api'
import Download      from '../node_modules/downloadjs/download.js'
import VueApexCharts from 'vue-apexcharts'
import { TokenService } from './services/storage'
import { usrGetters, usrActions } from "./store/user.module"
import '@/plugins/dayjs'

Vue.config.productionTip = false
Vue.prototype.$APP_COD = require('../package.json').appKey
Vue.prototype.$APP_VER = require('../package.json').version
/**************** Download JS ******************/
Object.defineProperty(Vue.prototype, '$download', { value: Download })
/**************** Storage js *******************/
Object.defineProperty(Vue.prototype, '$storage', { value: Storages })
/**************** Chart   **********************/
Vue.use(VueApexCharts)

Object.defineProperty(Vue.prototype, '$user', { value: { usrGetters, usrActions } }) //Vue.use(AuthPlugin, { router: router })

// Api service init.
ApiService.init( process.env.VUE_APP_API_URL )
if (TokenService.getToken()) {
  ApiService.setHeader()
}
ApiService.mount401Interceptor()
Vue.prototype.$http = ApiService;
// Event bus
Vue.prototype.$eventBus = new Vue({})

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
};

new Vue({
  vuetify,
  router,
  created: function(){

  },
  render: h => h(App)
}).$mount('#app')
