<template>
<v-card color="grey lighten-4" min-width="350px" flat >
    <v-toolbar color="primary" dark class="elevation-1" height="50px">
        <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
        <v-toolbar-title>Modèle d'entretien</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('template', 0, update)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-tabs v-model="activeTab" grow light show-arrows="mobile">
        <v-tabs-slider color="green"></v-tabs-slider>
        <v-tab ><v-icon class="mr-1">mdi-file</v-icon><span class="hidden-sm-and-down">Fiche</span></v-tab>
        <v-tab ><v-icon class="mr-1">mdi-calendar-check</v-icon><span class="hidden-sm-and-down">Période(s)</span></v-tab>
    </v-tabs>
    <v-card-text fluid class="mt-2">
        <v-layout v-if="loading.getgetTaskItems && activeTab === 0 && showTab == true" row wrap>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Nom" v-model="form.tla_name"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    item-text="typ_name" item-value="typ_id"
                    :items="getCacheType('TLI')" v-model="form.tla_typ_id" label="Type" style="z-index: 2000;">
                </v-select>
            </v-flex>
            <v-flex xs12 class="pa-2">
                <v-textarea rows="1" prepend-icon="mdi-comment" label="Description" v-model="form.tla_description" ></v-textarea>
            </v-flex>

            <template v-for="(item, index) in taskItems">
                <v-flex xs4 class="pa-1" :key="index">
                    <auto-multi :title="selectFields[index].title" 
                        :listParent="item" :fields="selectFields[index]" :listObject.sync="taskSelected[index]">
                    </auto-multi>
                </v-flex>
            </template>
        <v-flex xs12 class="pa-1">
        </v-flex>
        </v-layout>
        <v-alert v-if="loading.getgetTaskItems && activeTab === 1 && form.tla_id == 0" class="pl-4 pr-4 ma-2" border="left" dense type="warning" elevation="1">
            <i>Vous devez enregistrer la fiche avant de paramétrer des périodes.</i>
        </v-alert>
        <v-layout v-if="loading.getgetTaskItems && activeTab === 1 && form.tla_id > 0" row wrap>
            <v-layout class="ma-3 elevation-1" style="background-color:white;" row wrap>
            <v-flex xs3 class="pa-1 ma-3">
                    <v-text-field dense class="pr-3" label="Fréquence"  v-model="periodItem.per_frequency" ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-1 ma-3">
                <v-select dense
                    :items="periodType" item-text="name" item-value="id" 
                    v-model="periodItem.per_unit_freq" label="Type" single-line ></v-select>
            </v-flex>
            <v-flex xs3 class="pa-1 ma-3">
                    <v-text-field dense class="pr-3" label="Réccurence (0 pour illimité)"  v-model="periodItem.per_recurrence" ></v-text-field>
            </v-flex>
            <v-flex xs8 class="pa-1 ma-3">
                <v-text-field dense class="pr-3" label="Description"  v-model="periodItem.per_description" ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-1 ma-3">
                <v-btn outlined rounded small color="green" @click="savePeriod()">
                    Ajouter<v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-flex>
            </v-layout>

            <!-- Table -->
            <v-flex xs12 class="pa-1">
            <v-data-table class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="periodHeaders" :items="periodItems" item-key="tla_id"
                items-per-page-text="Lignes par page" :items-per-page="periodPagination.rowsPerPage" :footer-props="footer" hide-default-footer>
                <template v-slot:[`item.tla_typ_id`]="{ item }">
                    <v-chip small outlined label :color="getCacheType('TLI', item.tla_typ_id).typ_color">{{ getCacheType('TLI', item.tla_typ_id).typ_name }}</v-chip>
                </template>
                <template v-slot:[`item.per_id`]="{ item }" :color="'grey'">
                    <v-icon left color='red' title="Supprimer" v-if="isAuthorized( 'Templateaction', 'destroy' )" @click="deletePeriod(item.per_id)">
                        mdi-delete-forever
                    </v-icon>
                </template>
            </v-data-table>
            </v-flex>
        </v-layout>
    </v-card-text>
    <v-divider light></v-divider>
    <v-card-actions v-show="activeTab === 0">
        <v-btn v-if="form.tla_id" class="ma-0" color="error" @click.native="deleteItem()" :disabled="saveProgress">
            <v-icon left>mdi-trash-can</v-icon>Supprimer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :loading="saveProgress" @click.native="saveItem()" :disabled="saveProgress">
            Enregistrer
        </v-btn>
        <v-btn outlined color="grey" @click="switchModal('template', 0, update)">
            Fermer
        </v-btn>
    </v-card-actions>
</v-card>

</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'site_edit',
    props: ['toast', 'msdId', 'tlaId', 'switchModal'], 
    data: function () {
        return {
            activeTab: 0, showTab: true,
            update: false,
            loading: { getgetTaskItems: false },
            saveProgress: false,
            validFrom: true,
            locDateFormat: dateLocale, // Format de date
            taskItems    : {},
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            typeState: cacheGetters.getTypes(),
            form: {
                tla_id: 0, tla_sta_id: 'GEN01', tla_typ_id: 'TLI01', tla_msd_id: this.msdId, tla_name: '', tla_description: '', 
                tla_tasklist: [], tla_usr_id_created: 0, tla_created_at: null, tla_updated_at: null
            },
            periodItem: {
                per_id: 0, per_tla_id: 0, per_sta_id: 'GEN01', per_frequency: 1, per_unit_freq: 'm', per_recurrence: 0, 
                per_description: '', per_execution: 0, 
            },
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            periodPagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            periodItems: [],
            periodEditId: 0,
            periodHeaders: [
                { text: 'Fréquence', align: 'left', value: 'per_frequency'},
                { text: 'Type', align: 'left', value: 'per_unit_freq'},
                { text: 'Description', align: 'right', value: 'per_description'},
                { text: 'Action', align: 'right', value: 'per_id'},
            ],
            periodType: [{name:'Jour', id:'d'}, {name:'Mois', id:'m'}, {name:'Année', id:'y'}, {name:'Km', id:'km'}, {name:'Heure', id:'h'}],
            isAuthorized: usrGetters.getScopeRight
        }
    },
    beforeMount: function(){
        this.getTaskWithAction()
    },
    mounted: function (){

    },
    methods: {
        async getTaskWithAction(){
            if( this.tlaId > 0 ){
                this.form.tla_id            = this.tlaId
                this.periodItem.per_tla_id  = this.tlaId
                await this.getItem()
                await this.getPeriod()
            }
            await this.getTaskItems()
        },
        getTaskItems(){
            return new Promise( (resolve, reject) => {
                let response = { data : { data: cacheGetters.getTaskType() } }
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        if( this.taskItems[element.tty_typ_id] === undefined ){
                            this.$set(this.taskItems, element.tty_typ_id, [])
                            this.$set(this.taskSelected, element.tty_typ_id, [])
                            this.$set(
                                this.selectFields, element.tty_typ_id, 
                                {
                                    "title": this.getCacheType('TSK', element.tty_typ_id).typ_name,
                                    "index":"tty_id","text":"tty_name","preprendIcon":undefined,
                                    "autoselectfirst":false,"params":"", "outlined": true, "dense": true
                                }
                            )
                            
                        }
                        if( this.form.tla_tasklist.includes( parseInt(element.tty_id) ) ){
                            this.taskSelected[ element.tty_typ_id ].push( element.tty_id )
                        }
                        this.taskItems[element.tty_typ_id].push(element)
                        this.loading.getgetTaskItems = true
                        resolve('resolved')
                    })
                } else {
                    reject('error')
                }
            })
        },
        getItem() {
            return new Promise( (resolve, reject) => {
                this.$http.get( '/templatesaction/' + this.form.tla_id )
                .then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                    this.form.msd_customfields = (!this.form.msd_customfields) ? {} : this.form.msd_customfields
                    this.loading.getItem = false
                    resolve('resolved')
                })
                .catch( (error) => {
                    this.toast({ color: 'warning', text: error })
                    reject(error)
                })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.form.tla_tasklist = []
            for( let prop in this.taskSelected ){
                this.taskSelected[prop].forEach(element => {
                    this.form.tla_tasklist.push( element )
                })
            }
            this.saveProgress    = true
            if( parseInt( this.form.tla_id ) > 0 ){
                this.$http.put( '/templatesaction/' + this.form.tla_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress    = false
                    this.update = true
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/templatesaction/', this.form )
                .then( (response) => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.form.tla_id           = response.data.data.tla_id
                    this.periodItem.per_tla_id = response.data.data.tla_id
                    this.saveProgress = false
                    this.update = true
                    this.getItem()
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/templatesaction/' + this.form.tla_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.update = true
                        this.switchModal('template', 0, this.update)
                    })
                }
            })
        },
        getPeriod(){
            return new Promise( (resolve, reject) => {
                this.periodItems = []
                this.$http.get( '/periodicities/?per_page=false&per_tla_id=' + this.form.tla_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.periodItems = response.data.data
                        resolve('resolved')
                    } else {
                        resolve('resolved')
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        savePeriod(){
            // Enregistrement du formulaire
            this.saveProgress          = true
            this.periodItem.per_tla_id = this.form.tla_id
            this.$http.post( '/periodicities/', this.periodItem )
            .then( () => {
                this.periodItem.per_frequency   = 1
                this.periodItem.per_unit_freq   = 'm'
                this.periodItem.per_recurrence  = 0
                this.periodItem.per_description = ''
                this.getPeriod()
                this.saveProgress = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
            });
        },
        deletePeriod(per_id){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de période', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/periodicities/' + per_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.getPeriod()
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>