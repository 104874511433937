export const scopeCredential = {

        Generic: {
            index:      ['ADMIN_USER', 'SUPER_USER','WORKSHOP_SUPERVISOR','WORKSHOP_LEADER','MECHANIC','WORK_SUPERVISOR','AGENT','INTER_USER','EXTERN_USER','PARTNER_USER'],
            show:       ['ADMIN_USER', 'SUPER_USER','WORKSHOP_SUPERVISOR','WORKSHOP_LEADER','MECHANIC','WORK_SUPERVISOR','AGENT','INTER_USER','EXTERN_USER','PARTNER_USER'],
            create:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            store:      ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            update:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastsheet:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastevent:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        },
        Dashboard: {
            index:      ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            show:       ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            create:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            update:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastsheet:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            lastevent:  ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
        },
        Document : {
            read:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'],
            create:     ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR', 'MECHANIC'],
            edit:   ['ADMIN_USER', 'SUPER_USER', 'INTER_USER'],
            delete: ['ADMIN_USER', 'SUPER_USER', 'INTER_USER']
        },
        dashboard :           { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR', 'MECHANIC'] },
        dashboardalert :      { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR'] },
        preventive :          { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR'] },
        curative :            { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR', 'MECHANIC'] },
        planning :            { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR'] },
        times :               { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR', 'MECHANIC'] },
        timesuser :           { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR', 'MECHANIC'] },
        documents :           { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR'] },
        reports :             { index: ['ADMIN_USER', 'SUPER_USER', 'WORKSHOP_LEADER', 'WORKSHOP_SUPERVISOR'] },
        maintenancestandard : { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        equipments :          { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        tasktypes :           { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        category :            { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        group :               { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        brand :               { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        brandmodel :          { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        entity :              { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        contacts :            { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        stores :              { index: ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER'] },
        states :              { index: ['ADMIN_USER'] },
        types :               { index: ['ADMIN_USER'] },
        users :               { index: ['ADMIN_USER'] },
        importdata :          { index: ['ADMIN_USER'] },
        jobs     : {
            index:   ['ADMIN_USER', 'SUPER_USER' ],
            read:   ['ADMIN_USER', 'SUPER_USER' ],
            edit:   ['ADMIN_USER', 'SUPER_USER' ],
            delete: ['ADMIN_USER', 'SUPER_USER' ]
        },
}

export const scopeMutations = {
    setAccess( access ) {
        let prop
        for( prop in access ){
            scopeCredential[prop] = access[prop];
        }
    },
}