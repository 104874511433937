import { scopeCredential } from "./scopeCredential"

const ChMain           = () => import(/* webpackChunkName: "build-glob" */ './layouts/Main.vue') 
const ChDasboard       = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard.vue')
const ChDasboardAlert  = () => import(/* webpackChunkName: "build-glob" */ './pages/Dashboard_alert.vue')
const ChListPre        = () => import(/* webpackChunkName: "build-glob" */ './pages/Preventive.vue')
const ChListCur        = () => import(/* webpackChunkName: "build-glob" */ './pages/Curative.vue')
//const ChMap       = () => import(/* webpackChunkName: "build-map" */ './pages/Map.vue')
const ChDocument       = () => import(/* webpackChunkName: "build-glob" */ './pages/Document.vue')
const ChSlider         = () => import(/* webpackChunkName: "build-glob" */ './components/Slider_equipment.vue')
const ChViewAction     = () => import(/* webpackChunkName: "build-glob" */ './components/View_action.vue')
const ChPreViewEqu     = () => import(/* webpackChunkName: "build-glob" */ './components/View_equipment.vue')
const ChPlanning       = () => import(/* webpackChunkName: "build-glob" */ './pages/Planning.vue')
const ChTimesUser      = () => import(/* webpackChunkName: "build-glob" */ './pages/Timebyuser.vue')

export default {
menu : [
    { 'href': '/dashboard',     'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard',          'role': scopeCredential.dashboard.index },
    { 'href': '/dashboardalert','title': 'Synthèse alertes', 'icon': 'mdi-alert',                  'role': scopeCredential.dashboardalert.index },
    { 'href': '/preventive',    'title': 'Préventif', 'icon': 'mdi-calendar-check',                'role': scopeCredential.preventive.index },
    { 'href': '/curative',      'title': 'Curatif', 'icon': 'mdi-format-list-checks',              'role': scopeCredential.curative.index },
    { 'href': '/planning',      'title': 'Planning entretien', 'icon': 'mdi-calendar-multiselect', 'role': scopeCredential.planning.index },
    { 'href': '/times',         'title': 'Validation temps', 'icon': 'mdi-calendar-multiselect',   'role': scopeCredential.times.index },
    //{ 'href': '/timesuser',     'title': 'Temps utilisateur', 'icon': 'mdi-calendar-multiselect',  'role': scopeCredential.timesuser.index  },
    { 'href': '/documents',     'title': 'Documents', 'icon': 'mdi-briefcase',                     'role': scopeCredential.documents.index  },
    //{ 'href': '/myplanning',    'title': 'Mon planning', 'icon': 'mdi-calendar-multiselect', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { divider: true },
    { 'header': 'Reporting', 'role': ['ADMIN_USER', 'SUPER_USER', 'INTER_USER', 'EXTER_USER'] },
    { 'href': '/reports', 'title': 'Export', 'icon': 'mdi-file-chart', 'role': scopeCredential.reports.index },
    { divider: true },
    { 'header': 'Paramètres',      'title': 'Paramètres', 'icon': 'mdi-cogs', 'role':                             ['ADMIN_USER', 'WORKSHOP_SUPERVISOR', 'SUPER_USER', 'INTER_USER', 'PARTE_USER'], items: [
        { 'href': '/maintenancestandard',   'title': 'Fiche entretien', 'icon': 'mdi-format-list-checks',       'role': scopeCredential.maintenancestandard.index },
        { 'href': '/equipments',    'title': 'Materiel', 'icon': 'mdi-mower-bag',                               'role': scopeCredential.equipments.index },
        { 'href': '/tasktypes',    'title': 'Type de tache', 'icon': 'mdi-hammer-wrench',                       'role': scopeCredential.tasktypes.index },
        { 'href': '/category',     'title': 'Catégories', 'icon': 'mdi-format-list-numbered',                   'role': scopeCredential.category.index },
        { 'href': '/group',        'title': 'Groupes', 'icon': 'mdi-group',                                     'role': scopeCredential.group.index },
        { 'href': '/brand',        'title': 'Marques', 'icon': 'mdi-watermark',                                 'role': scopeCredential.brand.index },
        { 'href': '/brandmodel',   'title': 'Modèles', 'icon': 'mdi-animation-outline',                         'role': scopeCredential.brandmodel.index },
        { 'href': '/entity',       'title': 'Prestataires', 'icon': 'mdi-account-box-multiple',                 'role': scopeCredential.entity.index },
        { 'href': '/contacts',     'title': 'Gestion des contacts', 'icon': 'mdi-card-account-details-outline', 'role': scopeCredential.contacts.index },
        { 'href': '/stores',       'title': 'Gestion des dépôts', 'icon': 'mdi-store-outline',                  'role': scopeCredential.stores.index },
        { 'href': '/states',       'title': 'Gestion états', 'icon': 'mdi-tag-multiple',                        'role': scopeCredential.states.index },
        { 'href': '/types',        'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type',       'role': scopeCredential.types.index },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account',                'role': scopeCredential.users.index },
    { 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': scopeCredential.importdata.index },
    { 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench',      'role': scopeCredential.jobs.index },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'loginsso', path: '/login/:authcode',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard', path: '/dashboard', 
                components: { default: ChDasboard, modal: ChSlider },
                children: [
                  { name: 'dash_slide_prev', path: 'sheet/:equ_id', meta: { component: 'dashboardView' }, components: { content: ChPreViewEqu } },
                  { name: 'dash_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'dash_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'dashboardalert', path: '/dashboardalert', 
                components: { default: ChDasboardAlert, modal: ChSlider },
                children: [
                  { name: 'daal_slide_prev', path: 'sheet/:equ_id', meta: { component: 'alertView' }, components: { content: ChPreViewEqu } },
                  { name: 'daal_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'daal_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'preventive', path: '/preventive', 
                components: { default: ChListPre, modal: ChSlider },
                children: [
                  { name: 'pre_slide_prev', path: 'sheet/:equ_id', meta: { component: 'preventiveView' }, components: { content: ChPreViewEqu } },
                  { name: 'pre_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'pre_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } },
                  { name: 'pre_slide_view_kind', path: 'sheet/:equ_id/action/:act_id/:act_typ_id/:act_typ_id_kind', components: { content: ChViewAction } }
                ]
            },
            { name: 'curative', path: '/curative', 
                components: { default: ChListCur, modal: ChSlider },
                children: [
                  { name: 'cur_slide_prev', path: 'sheet/:equ_id', meta: { component: 'curativeView' }, components: { content: ChPreViewEqu } },
                  { name: 'cur_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'cur_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
/*            { name: 'map', path: '/map', 
                components: { default: ChMap, modal: ChSlider },
                children: [
                  { name: 'map_slide_prev', path: 'sheet/:equ_id', meta: { component: 'equipmentView' }, components: { content: ChPreViewEqu } },
                  { name: 'map_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'map_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },*/
            { name: 'document', path: '/documents', 
                components: { default: ChDocument, modal: ChSlider },
                children: [
                  { name: 'doc_slide_prev', path: 'sheet/:equ_id', meta: { component: 'documentView' }, components: { content: ChPreViewEqu } },
                  { name: 'doc_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'doc_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'planning', path: '/planning', 
                components: { default: ChPlanning, modal: ChSlider },
                children: [
                  { name: 'plan_slide_prev', path: 'sheet/:equ_id', meta: { component: 'planningView' }, components: { content: ChPreViewEqu } },
                  { name: 'plan_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'plan_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'timesuser', path: '/timesuser', 
                components: { default: ChTimesUser, modal: ChSlider },
                children: [
                  { name: 'tie_slide_prev', path: 'sheet/:equ_id', meta: { component: 'timeuserView' }, components: { content: ChPreViewEqu } },
                  { name: 'tie_slide_edit', path: 'sheet/:equ_id/modifier', components: { content: ChViewAction } },
                  { name: 'tie_slide_view', path: 'sheet/:equ_id/action/:act_id', components: { content: ChViewAction } }
                ]
            },
            { name: 'times',          path: '/times',      component: require('./pages/Time.vue').default },
            //{ name: 'timesuser',      path: '/timesuser/:usr_id',  component: require('./pages/Timebyuser.vue').default },
            { name: 'myplanning',     path: '/myplanning', component: require('./pages/Myplanning.vue').default },
            { name: 'report',         path: '/reports',    component: require('./pages/Report.vue').default },
            
            { name: 'users',          path: '/users',              component: require('./pages/Users.vue').default },
            { name: 'useredit',       path: '/users/:usr_id',      component: require('./pages/User_edit.vue').default },
            { name: 'states',         path: '/states',             component: require('./pages/States.vue').default },
            { name: 'statesedit',     path: '/states/:sta_id',     component: require('./pages/States_edit.vue').default },
            { name: 'types',          path: '/types',              component: require('./pages/Types.vue').default },
            { name: 'typesedit',      path: '/types/:typ_id',      component: require('./pages/Type_edit.vue').default },
            { name: 'entity',         path: '/entity',             component: require('./pages/Entity.vue').default },
            { name: 'entity_view',    path: '/entity/:ent_id',     component: require('./pages/Entity_edit.vue').default },
            { name: 'contacts',       path: '/contacts',           component: require('./pages/Contacts.vue').default },
            { name: 'contacts_view',  path: '/contacts/:ctt_id',   component: require('./pages/Contacts_edit.vue').default },
            { name: 'equipments',     path: '/equipments',         component: require('./pages/Equipment.vue').default },
            { name: 'equipmentsedit', path: '/equipments/:equ_id', component: require('./pages/Equipment_edit.vue').default },
            { name: 'brandmodel',     path: '/brandmodel',         component: require('./pages/BrandModel.vue').default },
            { name: 'brandmodeledit', path: '/brandmodel/:bmo_id', component: require('./pages/BrandModel_edit.vue').default },
            { name: 'category',       path: '/category',           component: require('./pages/Category.vue').default },
            { name: 'categoryedit',   path: '/category/:cat_id',   component: require('./pages/Category_edit.vue').default },
            { name: 'group',          path: '/group',              component: require('./pages/Group.vue').default },
            { name: 'groupedit',      path: '/group/:grp_id',      component: require('./pages/Group_edit.vue').default },
            { name: 'brand',          path: '/brand',              component: require('./pages/Brand.vue').default },
            { name: 'brandedit',      path: '/brand/:bra_id',      component: require('./pages/Brand_edit.vue').default },
            { name: 'stores',         path: '/stores',             component: require('./pages/Store.vue').default },
            { name: 'storesedit',     path: '/stores/:str_id',     component: require('./pages/Store_edit.vue').default },
            
            { name: 'tasktypes',       path: '/tasktypes',        component: require('./pages/Tasktype.vue').default },
            { name: 'tasktypesedit',   path: '/tasktypes/:tty_id', component: require('./pages/Tasktype_edit.vue').default },
            { name: 'maintenancestandard',   path: '/maintenancestandard', component: require('./pages/MaintenanceStandard.vue').default },
            { name: 'maintenancestandardedit', path: '/maintenancestandard/:msd_id', component: require('./pages/MaintenanceStandard_edit.vue').default },
            
            { name: 'importdata',   path: '/importdata', component: require('./pages/ImportData.vue').default },
            { name: 'jobs',         path: '/jobs', component: require('./pages/BackgroundJobs.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}