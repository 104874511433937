<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.grp_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.grp_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom" v-model="form.grp_name"  ></v-text-field> <!-- v-model="form.equ_name" -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi :title="'Groupe parent'" :ressource="'groups'"
                :listObject.sync="listItem" :listParent.sync="listItemSource"
                :fields="{ 
                    index: 'grp_id', text:'grp_name', autofocus: true,
                    search:'grp_name', preprendIcon:'mdi-table-merge-cells', multiSelect:false, 
                    params:'' 
                }">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.grp_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }   from '../mixins/equ_obj.js'
import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'equipmentedit',
    props: ['toast', 'contextStyle'],
    mixins: [ equObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            form:{
                grp_id: 0, grp_id_parent: null, grp_uid: null, grp_sta_id: 'GEN01', grp_name: '', grp_updated_at: null
            },
            listItem: [],
            listItemSource: [],
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.grp_name.length > 0 ){
                return true
            } else {
                return false
            }
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.grp_id ) > 0 && this.$route.params.grp_id !== undefined ){
            this.form.grp_id = this.$route.params.grp_id
            this.getItem()
        }
    },
    methods: {
        getItem() {
            // Toodo : get parent
            this.$http.get( '/groups/' + this.form.grp_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            this.form.grp_id_parent = (this.listItem > 0) ? this.grp_id_parent : null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.grp_id > 0 ){
                this.$http.put( '/groups/' + this.$route.params.grp_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/group' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/groups/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/group' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>