<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" :to="'/' + $route.name + '/0'">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="usr_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1"></v-col>
            </v-row>
        </template>
        <template v-slot:[`header.usr_role`]="{ header }">
            <v-menu offset-y rounded :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :color="(headFilter.usr_role.length) ? 'green' : 'grey'"><v-icon small>mdi-filter</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in roleLabels()" :key="index" @mousedown.prevent>
                        <v-list-item-action><v-checkbox color="primary" :value="index" @click="updateHeaderFilter('usr_role', index)" hide-details></v-checkbox></v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>
                            {{ roleLabels(index) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu> 
            {{ header.text }}
        </template>
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.usr_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.usr_lastName }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text">{{ item.usr_firstName }}</span>
                <br />
                <span class="ma-0 pl-0">
                    <v-icon small>mdi-account</v-icon>
                    <span class="grey--text">{{ item.usr_username }}</span>
                    &nbsp;<v-icon small>mdi-email-outline</v-icon>
                    <span class="grey--text">{{ item.usr_email }}</span>
                </span>
            </td>
            <td class="text-xs-left"></td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ roleLabels((item.usr_role) ? item.usr_role : 'BASIC_USER') }}</v-chip>
            </td>
            <td class="text-right">
                <v-icon left class="mr-2">mdi-chart-histogram</v-icon>
                <span class="grey--text font-italic">{{ locDateFormat.toLocale(item.usr_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/users/' + item.usr_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
    <v-dialog v-model="modalState" max-width="1000" @keydown.esc="modalState = false" :persistent="false">
        <v-card style="z-index:1100;">
        <v-toolbar color="primary" dark class="elevation-2" height="50px">
            <v-icon class="mr-3">mdi-chevron-down</v-icon>
            <v-toolbar-title>Erreur de log</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalState = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text >
            {{ errorLog }}
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { usrGetters }   from "../store/user.module"
import { cacheMutations } from "../store/cache.module"

export default {
    name: 'users',
    props: ['toast', 'contextStyle'],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            modalState: false,
            errorLog: 'Aucune erreur',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headFilter: { usr_role: [] },
            headers: [
            { text: 'Nom',      align: 'left', value: 'usr_lastName'},
            { text: 'Mail',     align: 'left', value: 'usr_email'},
            { text: 'Role',     value: 'usr_role' },
            { text: 'Création', value: 'usr_created_at' }
            ],
            items: [],
            locDateFormat: dateLocale, // Format de date
        }
    },
    mounted: function (){
        this.getItems()
    },
    methods: {
        getItems(options){
            options = options || ''
            this.items = []
            this.$http.get( '/users/?per_page=false' + options ).then( (response) => {
                this.items = response.data.data
            })
        },
        goRoute(id){
            if( this.$user.usrGetters.getScopeRight('users', 'read') ){
                this.$router.push({ path: '/users/' + id })
            } else {
                this.toast({ text: 'Droits insuffisant pour modifier une fiche' })
            }
        },
        getErrorLog(){
            this.$http.get( '/errorlog' ).then( (response) => {
                this.errorLog = response.data
                this.modalState = true
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('user', this.search)
        },
        updateHeaderFilter(filter, value){
            let options = ''
            let index   = this.headFilter[filter].indexOf( value )
            if( index > -1 ){
                this.headFilter[filter].splice(index, 1)
            } else {
                this.headFilter[filter].push(value)
            }
            options = this.headFilter[filter].join(',')
            if( options.length > 1 ){
                this.getItems( '&usr_role=' + options )
            } else {
                this.getItems()
            }
        },
        isAuthorized: usrGetters.getScopeRight,
        roleLabels: usrGetters.getRolesLabels,
    }
}
</script>

<style lang="css">

</style>