<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.bra_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.bra_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom" v-model="form.bra_name"  ></v-text-field> <!-- v-model="form.equ_name" -->
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.bra_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
//import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'brandedit',
    props: ['toast', 'contextStyle'],
    mixins: [ ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            form:{ 
                bra_id:0, bra_uid:'', bra_sta_id: 'GEN01', bra_name: '', bra_updated_at: null
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.bra_name.length > 0 ){
                return true
            } else {
                return false
            }
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.bra_id ) > 0 && this.$route.params.bra_id !== undefined ){
            this.form.bra_id = this.$route.params.bra_id
            this.getItem()
        }
    },
    methods: {
       getItem() {
            this.$http.get( '/brands/' + this.form.bra_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.bra_id > 0 ){
                this.$http.put( '/brands/' + this.$route.params.bra_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/brand' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/brands/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/brand' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/brand/' + this.form.bra_id).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/brand' })
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        //'auto-multi': autoCompMulti
    }
}
</script>