import Vue        from 'vue'
import VueRouter  from 'vue-router'
import menu       from '../confnav' // menu + routes
import { TokenService } from '../services/storage'
import { cacheData } from "../store/cache.module"
import { scopeCredential } from "../scopeCredential"

/**************** Routeur  *********************/
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'hash',
  base: __dirname,
  routes: menu.routes
})

/**************** Authentification ******************/
router.beforeEach((to, from, next) => {
  const isPublic          = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn          = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({ path:'/login', query: {redirect: to.fullPath} })  // Store the full path to redirect the user to after login
  }
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/') // Do not allow user to visit login page or register page if they are logged in
  }
  if( scopeCredential[to.name] !== undefined && ( scopeCredential[to.name]['index'] !== undefined || scopeCredential[to.name]['update'] !== undefined ) ){
    scopeCredential[to.name]['update'] = scopeCredential[to.name]['update'] || []
    scopeCredential[to.name]['index']  = scopeCredential[to.name]['index'] || [cacheData.user.usr_role] // todoo
    let index  = scopeCredential[to.name]['index'].indexOf( cacheData.user.usr_role )
    let update = scopeCredential[to.name]['update'].indexOf( cacheData.user.usr_role )
    if( index < 0 && update < 0 ){
      return next('/forbidden')
    }
  }

  next()
})

export default router