<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-card-text>
            <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Nom"   v-model="form.str_name" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    item-text="agc_name" item-value="agc_id"
                    :items="agencies" v-model="form.str_agc_id" label="Agence" single-line >
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Description"   v-model="form.str_description" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Adresse"   v-model="form.str_address" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Code postal"   v-model="form.str_postalcode" ></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Ville"   v-model="form.str_city" ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-select 
                    item-text="sta_label" item-value="sta_id"
                    :items="getCacheState('GEN')" v-model="form.str_sta_id" label="Statut" single-line >
                </v-select>
            </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/stores/">Retour</v-btn>
        </v-card-actions>
    </v-card>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'session_edit',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            dateMenu: { date_start:false, date_end:false },
            form:{
                str_id:0, str_uid:'', str_agc_id:null, str_sta_id:'GEN01', 
                str_name:'', str_description:'', str_code:'', str_address:'', 
                str_postalcode:'', str_city:'', str_lat: null, str_lng: null, str_customfields: null
            },
            locDateFormat: dateLocale, // Format de date
            ses_id: 0,
            agencies: []
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.ses_id ) > 0 && this.$route.params.ses_id !== undefined ){
            this.form.ses_id = this.$route.params.ses_id
            this.getItem()
        }
        this.getAgencies()
    },
    methods: {
        async getItem(){
            this.$http.get( '/stores/' + this.$route.params.ses_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.form.ses_id > 0 ){
                this.$http.put( '/stores/' + this.form.ses_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                    this.$router.push({ path: '/stores/' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/stores/', this.form ).then( (response) => {
                    for( let prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                    this.saveProgress = false
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/stores/' + this.form.ses_id })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        getAgencies(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/agencies?per_page=false' ).then( (response) => {
                    this.agencies = response.data.data
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {

    }
}
</script>