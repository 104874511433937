<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass">
            <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
            <span>{{ contextStyle.title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="success" depressed class="white--text" to="/stores/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn> -->
    </v-toolbar>

    <v-toolbar dense class="ma-4 rounded-lg">
        <v-btn icon @click="getMondayOfCurrentWeek(-7)"><v-icon>mdi-arrow-left-bold-outline</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-icon>mdi-calendar</v-icon>&nbsp;
         Semaine du &nbsp;<b>{{monday.toLocaleDateString("fr")}}</b>&nbsp;au&nbsp;<b>{{monday.addDays(6).toLocaleDateString("fr")}}</b>
        <v-spacer></v-spacer>
        <v-btn icon @click="getMondayOfCurrentWeek(7)"><v-icon>mdi-arrow-right-bold-outline</v-icon></v-btn>
    </v-toolbar>

    <v-card class="ma-4 rounded-lg">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search" :items-per-page="-1"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" hide-default-footer>
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1"></v-col>
            </v-row>
        </template>

        <template v-slot:[`header.week_1`]="{ header }">{{ header.text }} <br /> {{ week.lundi.getDate()    }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.lundi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_2`]="{ header }">{{ header.text }} <br /> {{ week.mardi.getDate()    }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.mardi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_3`]="{ header }">{{ header.text }} <br /> {{ week.mercredi.getDate() }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.mercredi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_4`]="{ header }">{{ header.text }} <br /> {{ week.jeudi.getDate()    }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.jeudi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_5`]="{ header }">{{ header.text }} <br /> {{ week.vendredi.getDate() }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.vendredi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_6`]="{ header }">{{ header.text }} <br /> {{ week.samedi.getDate()   }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.samedi)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.week_7`]="{ header }">{{ header.text }} <br /> {{ week.dimanche.getDate() }} <v-btn v-if="isAuthorized( 'Worktime', 'validate' )" text @click.stop="validateTimeByDay(week.dimanche)"><v-icon color="grey">mdi-check</v-icon></v-btn></template>
        <template v-slot:[`header.total`]="{ header }">{{ header.text }}</template>
        <template v-slot:[`header.action`]="{ header }">{{ header.text }}</template>

        <template v-slot:[`item.usr_middleName`]="{ item }">
            <v-btn text @click="openUser(item.usr_id)"><strong flat color="blue" class="ma-0 subheading blue--text">{{ item.usr_middleName }}</strong></v-btn><br />
            <v-chip class="ml-4" small outlined label :color="getCacheType('WTI', item.wti_typ_id).typ_color">
                {{ getCacheType('WTI', item.wti_typ_id).typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.week_1`]="{ item }"><v-chip label :outlined="!item.week_1_validated" color="primary">{{ item.week_1 }}</v-chip><v-icon v-if="item.week_1_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_2`]="{ item }"><v-chip label :outlined="!item.week_2_validated" color="primary">{{ item.week_2 }}</v-chip><v-icon v-if="item.week_2_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_3`]="{ item }"><v-chip label :outlined="!item.week_3_validated" color="primary">{{ item.week_3 }}</v-chip><v-icon v-if="item.week_3_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_4`]="{ item }"><v-chip label :outlined="!item.week_4_validated" color="primary">{{ item.week_4 }}</v-chip><v-icon v-if="item.week_4_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_5`]="{ item }"><v-chip label :outlined="!item.week_5_validated" color="primary">{{ item.week_5 }}</v-chip><v-icon v-if="item.week_5_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_6`]="{ item }"><v-chip label :outlined="!item.week_6_validated" color="primary">{{ item.week_6 }}</v-chip><v-icon v-if="item.week_6_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.week_7`]="{ item }"><v-chip label :outlined="!item.week_7_validated" color="primary">{{ item.week_7 }}</v-chip><v-icon v-if="item.week_7_validated" small color="green">mdi-check</v-icon></template>
        <template v-slot:[`item.total`] ="{ item }"><v-chip color="blue-grey" label>{{ item.total }}</v-chip></template>
        <template v-slot:[`item.action`]="{ item }">{{ item.action }}<v-icon v-if="isAuthorized( 'Worktime', 'validate' )" @click.stop="validateTimeByUser(item)" color="green">mdi-check</v-icon></template>

        <template v-slot:foot>
            <tr style="background-color:#f2f2f2;">
                <td class="text-right">Total</td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_1 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_2 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_3 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_4 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_5 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_6 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3">{{ totalTime.week_7 }}</v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3"></v-chip></td>
                <td class="text-left"><v-chip small label class="ml-3"></v-chip></td>
            </tr>
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations, cacheData } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'times',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 25, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Utilisateur', align: 'left', value: 'usr_middleName'},
            { text: 'lundi',     align: 'left',  value: 'week_1', sortable: false },
            { text: 'mardi',     align: 'left',  value: 'week_2', sortable: false },
            { text: 'mercredi',  align: 'left',  value: 'week_3', sortable: false },
            { text: 'jeudi',     align: 'left',  value: 'week_4', sortable: false },
            { text: 'vendredi',  align: 'left',  value: 'week_5', sortable: false },
            { text: 'samedi',    align: 'left',  value: 'week_6', sortable: false },
            { text: 'dimanche',  align: 'left',  value: 'week_7', sortable: false },
            { text: 'Total',     align: 'left',  value: 'total', sortable: false },
            { text: 'Valider',   align: 'left',  value: 'action', sortable: false }
            
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
            monday: new Date(),
            week : { lundi: '', mardi: '', mercredi: '', jeudi: '', vendredi: '', samedi: '', dimanche: '' },
            totalTime: {},
            userTime: {}
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        user(){
            return cacheData.user
        },
    },
    beforeMount: function(){
        this.getMondayOfCurrentWeek()
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('times') || ''
    },
    methods: {
        getItems(){
            this.items    = []
            this.userTime = {}
            this.totalTime   = { week_1: 0, week_2: 0, week_3: 0, week_4: 0, week_5: 0, week_6: 0, week_7: 0 }

            let param  = '&min_wti_date=' + this.monday.yyyymmdd() + '&max_wti_date=' + this.monday.addDays(6).yyyymmdd()
            //if( !this.isAuthorized( 'Worktime', 'validate' ) ){
            //    param  = "&usr_id=" + this.user.usr_id
            //}
            this.$http.get( '/worktimes?per_page=false&extended=byday' + param ).then( (response) => {
                response.data.data.forEach(element => {
                    let key = element.usr_uid + '-' + element.wti_typ_id
                    this.userTime[key] = this.userTime[key] || element
                    if(!this.userTime[key]['durations']){
                        this.userTime[key]['durations'] = []
                    }
                    this.userTime[key]['durations'].push( element )
                })
                for( let prop in this.userTime ){
                    this.userTime[prop]['total'] = 0
                    this.userTime[prop]['durations'].forEach( (element) => {
                        let wtiDate = new Date(element.wti_date)
                        let key = 'week_' + wtiDate.getDay()
                        this.userTime[prop]['total'] += element.wti_duration_sum
                        this.userTime[prop][key] = element.wti_duration_sum
                        this.userTime[prop][key + '_validated'] = (element.wti_validated_min == 1) ? true : false

                        if(this.totalTime[key] !== undefined){
                            this.totalTime[key] += parseFloat(element.wti_duration_sum)
                        } else {
                            this.totalTime[key] = 0
                        }
                    })
                    this.items.push(this.userTime[prop])
                }
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('times', this.search)
        },
        getMondayOfCurrentWeek(increment) {
            increment = increment || 0
            this.monday = this.monday.addDays(increment)
            const today = new Date(this.monday)
            const first = today.getDate() - today.getDay() + 1

            const monday = new Date(today.setDate(first))
            this.week.lundi    = monday
            this.week.mardi    = monday.addDays(1)
            this.week.mercredi = monday.addDays(2)
            this.week.jeudi    = monday.addDays(3)
            this.week.vendredi = monday.addDays(4)
            this.week.samedi   = monday.addDays(5)
            this.week.dimanche = monday.addDays(6)

            this.monday = monday
            this.getItems()
        },
        validateTimeByDay(weekDay){
            const message = 'Valider les heures du ' + weekDay.yyyymmdd() + ' pour tous les utilisateurs ?'
            this.$root.$confirm('Confirmez vous cette action ?', message, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.put( '/worktimes/validatebyday/' + weekDay.yyyymmdd(), {} ).then( () => {
                        this.getItems()
                    })
                }
            })
        },
        validateTimeByUser(item){
            let lundi    = this.week.lundi.yyyymmdd()
            let dimanche = this.week.dimanche.yyyymmdd()
            let message = 'Valider les heures du ' + lundi + ' au ' + dimanche + ' pour : ' + item.usr_middleName
            this.$root.$confirm('Confirmez vous cette action ?', message, { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.put( '/worktimes/validatebyuser/' + item.usr_id, {
                        usr_id: item.usr_id,
                        wti_date_from: lundi,
                        wti_date_to: dimanche
                    } ).then( () => {
                        this.getItems()
                    })
                }
            })
        },
        openUser(usrId){
            let lundi    = this.week.lundi.toISOString().split('T')[0]
            let dimanche = this.week.dimanche.toISOString().split('T')[0]
            cacheMutations.setSearchBarItem('timesuser_wti_date_from', lundi)
            cacheMutations.setSearchBarItem('timesuser_wti_date_to', dimanche)
            cacheMutations.setSearchBarItem('timesuser_usr_id', usrId)
            this.$router.push( { path: '/timesuser' } )
        },
        beforeDestroy() {

        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>