<template>
    <v-card fluid class="ma-2 pa-5 elevation-2" color="grey lighten-4">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom"   v-model="form.ctt_lastname" ></v-text-field>
            <v-text-field label="Tel"   v-model="form.ctt_phone"></v-text-field>
            <v-text-field label="Autre" v-model="form.ctt_middlename"></v-text-field>
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.ctt_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Prénom" v-model="form.ctt_firstname" ref="firstname"></v-text-field>
            <v-text-field label="Mobile" v-model="form.ctt_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="form.ctt_email"></v-text-field>
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('CTT')" v-model="form.ctt_typ_id" label="Type" single-line >
            </v-select>
        </v-flex>
        </v-layout>
        <div class="text-right">
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">
            Enregistrer
            </v-btn>
            <v-btn color="primmary" @click.native="switchModal('modalContact', 0)">
            Retour
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'creat_contact',
    props: ['toast', 'switchModal', 'prefill', 'addToTeam'],
    data: () => ({
        saveProgress: false,         // Enregistrement en cours...
        dialog: false,
        form:{
            ctt_id: 0, ctt_sta_id: 'GEN01', ctt_typ_id: 'CTT01',
            ctt_firstname: '', ctt_lastname: '', ctt_middlename: '',
            ctt_phone: '', ctt_mobile: '', ctt_fax: '', ctt_adr_id: '',
            ctt_email: '', ctt_customfields: {}
        }, 
    }),
    mounted:function (){
        if( this.prefill.name !== null && this.prefill.name !== undefined ){
            this.form.ctt_lastname  = this.prefill.name.split(' ')[0] || ''
            this.form.ctt_firstname = this.prefill.name.split(' ')[1] || ''
        }
        this.$refs.firstname.focus()
    },
    methods: {
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.$route.params.ctt_id ){
                this.$http.put( '/contacts/' + this.$route.params.ctt_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    //this.addToTeam(response.data.data)
                    //this.$router.push({ path: '/contacts' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.form.ctt_middlename = this.form.ctt_firstname + ' ' + this.form.ctt_lastname
                this.$http.post( '/contacts/', this.form )
                .then( (response) => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.addToTeam(response.data.data)
                    this.switchModal('modalContact', 0)
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    }
}
</script>