<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="objEquipment.equ_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(objEquipment.equ_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Matricule" v-model="objEquipment.equ_code"  ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field ref="scanZone" @focus="scannfc()" :label="scanLabel"  v-model="objEquipment.equ_nfcid" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!assocItem" :title="'Catégories'" ressource="categories" :listObject.sync="autoComplete.categories" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.categories" :fields="{ 
                    index: 'cat_id', text:'cat_name,cat_code', search:'cat_name,cat_code', multiSelect:false, prefetch: true, resultlimit: 'false',
                    params: '&order_by=cat_name&order_direction=asc'
                }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="objEquipment.equ_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <!-- <v-text-field label="Modele"  v-model="objEquipment.equ_customfields.model" ></v-text-field> -->
            <auto-multi v-if="!assocItem" :title="'Modèle'" ressource="brandmodels" :listObject.sync="autoComplete.brandModel" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.brandModel" :fields="{ 
                    index: 'bmo_id', text:'bmo_code', search:'bmo_code', multiSelect:false, prefetch: true
                }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!assocItem" :title="'Conducteur associé'" ressource="users" :listObject.sync="autoComplete.user" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.user" :fields="{ 
                    index: 'usr_id', text:'usr_firstName,usr_lastName', search:'usr_middlename', 
                    multiSelect:true , params: 'usr_role=WORK_SUPERVISOR', prefetch: true
                }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!assocItem" :title="'Dépot'" ressource="stores" :listObject.sync="autoComplete.stores" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.stores" :fields="{ 
                    index: 'str_id', text:'str_name', search:'str_name', multiSelect:false, prefetch: true, resultlimit: 'false',
                }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!assocItem" :title="'Propriétaire'" ressource="entities" :listObject.sync="autoComplete.entities" :disabled="assocItem"
                :listParent.sync="autoCompleteSource.entities" :fields="{ 
                    index: 'ent_id', text:'ent_name', search:'ent_name', multiSelect:false, prefetch: true, resultlimit: 20,
                }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2"></v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('TRA')" v-model="objEquipment.equ_typ_id_trade" label="Mode aquisition" single-line >
            </v-select>
        </v-flex>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Informations complémentaires</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Série"  v-model="objEquipment.equ_serial" ></v-text-field>
                            <!-- <v-text-field label="Série"  v-model="objEquipment.equ_customfields.serie" ></v-text-field> -->
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Matricule interne"  v-model="objEquipment.equ_customfields.matricule" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Immatriculation 1"  v-model="objEquipment.equ_registration" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Immatriculation 2"  v-model="objEquipment.equ_registration_add" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Service"  v-model="objEquipment.equ_customfields.equ_service" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Code chantier"  v-model="objEquipment.equ_sig_code" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Valeur achat"  v-model="objEquipment.equ_customfields.equ_price" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="N° GPS"  v-model="objEquipment.equ_customfields.equ_tomtom" ></v-text-field>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Agent conducteur"  v-model="objEquipment.equ_customfields.equ_driver" ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale       from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }       from '../mixins/equ_obj.js'
import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'equipmentsedit',
    props: ['toast', 'contextStyle'],
    mixins: [ equObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            scanLabel: 'cliquez pour scanner',
            loading: { getItem: true },
            typeSpe : cacheGetters.getTypes('EQU'),
            categorieList: [],
            brandModelList: [],
            autoComplete: {
                user      : [],
                contact   : [],
                brandModel: [],
                categories: null,
                stores    : null,
                entities  : null
            },
            autoCompleteSource:{
                user     : [],
                contact  : [],
                brandModel: [],
                categories: [],
                stores    : [],
                entities  : []
            },
            assocItem: true
        }
    },
    computed: {
        validate () {
            //if( !this.saveProgress && this.objEquipment.equ_name.length > 0 ){
            //    return true
            //} else {
            //    return false
            //}
            return true
        },
        //matricule(){
        //   return this.objEquipment.equ_customfields.matricule
        //}
    },
    mounted:function (){
        if ('NDEFReader' in window) {
            this.scanLabel = 'cliquez pour scanner'
        } else {
            this.scanLabel = 'Votre appareil ne peut pas lire du NFC'
        }
        this.getItem()
    },
    methods: {
        async getItem(){
            //await this.getCategories()
            //await this.getBrandModel()
            if( parseInt( this.$route.params.equ_id ) > 0 && this.$route.params.equ_id !== undefined ){
                this.objEquipment.equ_id = this.$route.params.equ_id
                await this.getEquipmentShow('?include=user,brandmodel,store,entities')
                this.autoComplete.categories = this.objEquipment.equ_cat_id
                this.autoComplete.brandModel = this.objEquipment.equ_bmo_id
                this.autoComplete.stores     = this.objEquipment.equ_str_id
                this.autoComplete.entities   = this.objEquipment.equ_ent_id
                if(this.objEquipment._info.user){
                    this.objEquipment._info.user.forEach( (elem) => {
                        this.autoCompleteSource.user.push(elem)
                        this.autoComplete.user.push(elem.usr_id)
                    })
                }
                if(this.objEquipment._info.store){
                    this.autoCompleteSource.stores.push(this.objEquipment._info.store)
                }
                if(this.objEquipment._info.brandmodel){
                    this.autoCompleteSource.brandModel.push(this.objEquipment._info.brandmodel)
                }
            }

            this.assocItem = false
        },
        getCategories(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/categories?per_page=false&order_by=cat_name&order_direction=asc' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        this.autoCompleteSource.categories.push(response.data.data[prop])
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getBrandModel(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/brandmodels?per_page=false' ).then( (response) => {
                    this.brandModelList = response.data.data
                    var prop
                    for( prop in response.data.data ){
                        this.autoCompleteSource.brandModel.push(response.data.data[prop])
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.objEquipment.equ_ent_id = 1

            this.objEquipment.equ_cat_id = this.autoComplete.categories
            this.objEquipment.equ_bmo_id = this.autoComplete.brandModel
            this.objEquipment.equ_str_id = this.autoComplete.stores
            this.objEquipment.equ_ent_id = this.autoComplete.entities
            this.objEquipment._data.user = {}
            this.autoComplete.user.forEach(element => {
               this.objEquipment._data.user[element] = { eus_typ_id: 'EUS01' }
            });
            this.autoCompleteSource.stores.forEach(element => {
                if( this.autoComplete.stores == element.str_id ){
                    this.objEquipment.equ_agc_id = element.str_agc_id
                }
            });
            this.saveProgress    = true
            if( parseInt( this.objEquipment.equ_id ) > 0 ){
                this.$http.put( '/equipments/' + this.objEquipment.equ_id, this.objEquipment )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipments' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/equipments/', this.objEquipment )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/equipments' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/equipments/' + this.objEquipment.equ_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/equipments' })
                    })
                }
            })
        },
        async scannfc(){
            /*global NDEFReader*/
            try {
                this.scanLabel = 'Scannez la puce'
                const ndef = new NDEFReader()
                await ndef.scan()

                ndef.addEventListener("readingerror", () => {
                    this.scanLabel = "Impossible de lire la puce"
                })

                ndef.addEventListener("reading", ({ message, serialNumber }) => {
                    this.objEquipment.equ_uid = serialNumber
                    console.log(message)
                    //log(`> Records: (${message.records.length})`);
                });
            } catch (error) {
                this.scanLabel = "Argh! " + error
            }            
        },
        getCacheState: cacheGetters.getStates,
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>