<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Nom"    v-model="item.usr_lastName" ></v-text-field>
            <v-text-field label="Prénom" v-model="item.usr_firstName"></v-text-field>
            <v-text-field label="Login"  v-model="item.usr_username"></v-text-field>
            <v-text-field label="Mot de passe" type="password" v-model="item.usr_password" loading>
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Tel" v-model="item.usr_phone"></v-text-field>
            <v-text-field label="Mobile" v-model="item.usr_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="item.usr_email"></v-text-field>
            <v-select :items="roleList" item-value="value" v-model="item.usr_role" label="Role" single-line >
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(item.usr_updated_at, 'fr') }}
            </v-chip>
            </v-container>
            <v-text-field label="Matricule P" v-model="item.usr_employeenumber"></v-text-field>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()">Enregistrer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { usrGetters }   from "../store/user.module"

export default {
    name: 'user_card',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            item:{
              usr_id: 0, usr_uid:'', usr_username: '', usr_password: '',
              usr_lastName: '', usr_firstName: '',
              usr_phone: '', usr_mobile: '', usr_email: '',
              usr_created_at: '2000-01-01', usr_updated_at: '',
              usr_isActive: 1, usr_sta_id_type: 'USR01', usr_role: 'BASIC_USER',
              usr_employeenumber: ''
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.usr_email.length > 2 ){
                return false
            } else {
                return true
            }
        },
        roleList(){
            let roleTab   = this.roleLabels()
            let roleAssoc = []
            for( let prop in roleTab){
                roleAssoc.push({ value: prop, text: roleTab[prop] })
            }
            return roleAssoc
        }
    },
    mounted:function (){
        if( parseInt( this.$route.params.usr_id ) > 0 && this.$route.params.usr_id !== undefined ){
            this.$http.get( '/users/' + this.$route.params.usr_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.item[prop] !== undefined ){
                        this.item[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        }
    },
    methods: {
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( parseInt( this.$route.params.usr_id ) > 0 ){
                this.$http.put( '/users/' + this.$route.params.usr_id, this.item )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/users/', this.item )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                })
            }
        },
        deleteItem(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.usr_id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.usr_id).then( () => {
                        this.toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        isAuthorized: usrGetters.getScopeRight,
        roleLabels: usrGetters.getRolesLabels,
    },
    components: { }
}
</script>