<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.bmo_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.bmo_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Nom" v-model="form.bmo_code"  ></v-text-field> <!-- v-model="form.equ_name" -->
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('ENY')" v-model="form.bmo_typ_id_energy" label="Energie" single-line >
            </v-select>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="form.bmo_sta_id" label="Statut" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Groupe'" ressource="groups" :listObject.sync="autoComplete.grp_id"
                :listParent.sync="autoCompleteSource.grp" 
                :fields="{ index: 'grp_id', text:'grp_name', search:'grp_name', preprendIcon:'mdi-bookmark-multiple-outline', multiSelect:false }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <auto-multi v-if="!loading.getItem" :title="'Marque'" ressource="brands" :listObject.sync="autoComplete.bra_id" multiSelect:false
                :listParent.sync="autoCompleteSource.bra" 
                :fields="{ index: 'bra_id', text:'bra_name', search:'bra_name', preprendIcon:'mdi-family-tree', multiSelect:false }"
                class="ml-2 mr-2">
            </auto-multi>
        </v-flex>
        </v-layout>
    </v-card>
    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
    </v-toolbar>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'brandmodeledit',
    props: ['toast', 'contextStyle'],
    mixins: [ ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            form:{ 
                bmo_id:0, bmo_uid:'', bmo_sta_id: 'GEN01', bmo_code: '', bmo_typ_id_energy: 'ENY01', bmo_grp_id: 0, bmo_bra_id: 0, 
                bmo_msd_id: null, bmo_updated_at: null
            },
            autoComplete: {
                grp_id: null,
                bra_id: null,
            },
            autoCompleteSource:{
                grp: [],
                bra: [],
            },
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.bmo_code.length > 0 ){
                return true
            } else {
                return false
            }
        },
    },
    mounted:function (){
        if( parseInt( this.$route.params.bmo_id ) > 0 && this.$route.params.bmo_id !== undefined ){
            this.form.bmo_id = this.$route.params.bmo_id
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
       getItem() {
            this.$http.get( '/brandmodels/' + this.form.bmo_id + '?include=group,brand' )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                        this.form[prop] = response.data.data[prop]['data']
                    } else {
                        this.form[prop] = response.data.data[prop]
                    }
                }
                if( this.form.group ){
                    this.autoCompleteSource.grp.push(this.form.group)
                    this.autoComplete.grp_id = this.form.bmo_grp_id
                }
                if( this.form.brand ){
                    this.autoCompleteSource.bra.push(this.form.brand)
                    this.autoComplete.bra_id = this.form.bmo_bra_id
                }
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.form.bmo_grp_id       = this.autoComplete.grp_id || null
            this.form.bmo_bra_id       = this.autoComplete.bra_id || null
            this.saveProgress = true
            if( this.$route.params.bmo_id > 0 ){
                this.$http.put( '/brandmodels/' + this.$route.params.bmo_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/brandmodel' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            } else {
                this.$http.post( '/brandmodels/', this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/brandmodel' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/brandmodels/' + this.form.bmo_id).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/brandmodels' })
                    })
                }
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>