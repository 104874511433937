<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-0" flat color="barrinfo">
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo">
            <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip outlined color="blue" v-if="form.msd_updated_at">
            <v-icon left>mdi-information-outline</v-icon>Modification: {{ locDateFormat.formatLocale(form.msd_updated_at, 'fr') }}
        </v-chip>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Nom" v-model="form.msd_name"></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="statusList" v-model="form.msd_sta_id" label="Etat">
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Code"  v-model="form.msd_code" @change="nameControl()" ></v-text-field>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-textarea rows="1" prepend-icon="mdi-comment" label="Description" v-model="form.msd_description" ></v-textarea>
        </v-flex>
        <!-- <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Informations complémentaires</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                            <v-text-field label="Série"  v-model="form.msd_customfields.serie" ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels> -->
        </v-layout>
    </v-card>

    <v-card fluid class="ma-2 pa-5 elevation-2" v-show="form.msd_id">
    <v-layout row wrap>
        <v-flex xs6>
        <!-- Template -->
        <v-data-table class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="templateHeaders" :items="templateItems" item-key="tla_id"
            items-per-page-text="Lignes par page" :items-per-page="templatePagination.rowsPerPage" :footer-props="footer" hide-default-footer>
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info" icon="mdi-hammer-wrench">Actions</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined rounded small color="green" @click="templateEditId = 0; switchModal('template', 1)">
                    Ajouter<v-icon>mdi-playlist-edit</v-icon>
                </v-btn>
            </v-toolbar>
            </template>
            <template v-slot:[`item.tla_typ_id`]="{ item }">
                <v-chip small outlined label :color="getCacheType('TLI', item.tla_typ_id).typ_color">{{ getCacheType('TLI', item.tla_typ_id).typ_name }}</v-chip>
            </template>
            <template v-slot:[`item.tla_id`]="{ item }" :color="'grey'">
                <v-icon left color='orange' title="Editer" v-if="isAuthorized( 'Templateaction', 'update' )" @click="templateEditId = item.tla_id; switchModal('template', 1)">
                    mdi-pencil
                </v-icon>
                <!-- <v-icon left color='red' title="Supprimer" v-if="isAuthorized( 'Templateaction', 'destroy' )" @click="deleteDocument(item.tla_id)">
                    mdi-delete-forever
                </v-icon> -->
            </template>
        </v-data-table>
        </v-flex>
        <v-flex xs6>
        <!-- Models -->
        <v-data-table class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="modelHeaders" :items="modelItems" item-key="sta_id"
            items-per-page-text="Lignes par page" :items-per-page="modelPagination.rowsPerPage" :footer-props="footer" hide-default-footer>
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Modèles associés</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined rounded small color="green" @click="modelEditId = 0; switchModal('addmodel', 1)">
                    Ajouter<v-icon>mdi-playlist-edit</v-icon>
                </v-btn>
            </v-toolbar>
            </template>
            <template v-slot:[`item.bmo_typ_id_energy`]="{ item }">
                <v-chip small outlined label :color="getCacheType('ENY', item.bmo_typ_id_energy).typ_color">{{ getCacheType('ENY', item.bmo_typ_id_energy).typ_name }}</v-chip>
            </template>
            <template v-slot:[`item.bmo_id`]="{ item }" :color="'grey'">
                <v-icon left color='red' title="Supprimer" v-if="isAuthorized( 'Templateaction', 'destroy' )" @click="freeModel(item.bmo_id)">
                    mdi-delete-forever
                </v-icon>
            </template>
        </v-data-table>
        </v-flex>
    </v-layout>
    </v-card>
    <!-- alert message -->
    <v-alert v-show="!form.msd_id" class="pl-4 pr-4 ma-2" border="left" dense type="warning" elevation="1">
        <i>Vous devez enregistrer la fiche avant de paramétrer les actions et associer les modèles.</i>
    </v-alert>

    <v-toolbar extended flat color="transparent" class="elevation-0" >
        <v-btn rounded color="red" @click.native="deleteItem()"><v-icon left>mdi-delete</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn rounded text :href="contextStyle.breadcrumbs[0].href">
            <v-icon left dark>mdi-close</v-icon>Annuler
        </v-btn>
        <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="saveItem()" :disabled="!validate">Enregistrer</v-btn>
    </v-toolbar>

    <v-dialog v-model="modal" max-width="900" style="z-index:1056;">
        <edit-template class="elevation-2" v-if="modal && modalType == 'template'" :toast="toast" :switchModal="switchModal" :tlaId="templateEditId" :msdId="form.msd_id"></edit-template>
        <edit-addmodel class="elevation-2" v-if="modal && modalType == 'addmodel'" :toast="toast" :switchModal="switchModal" :bmoId="modelEditId" :msdId="form.msd_id"></edit-addmodel>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale            from '../services/dateLocale';
import { cacheGetters }      from "../store/cache.module"
import editTemplate          from '../components/Edit_template.vue'
import editAddModelTemplate  from '../components/Edit_addmodel.vue'
import { usrGetters }   from "../store/user.module"
//import autoCompMulti    from '../components/Autocomplete_multi.vue'

export default {
    name: 'equipmentedit',
    props: ['toast', 'contextStyle'],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            modal    : false,
            modalType: '',
            taskItems    : {},
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            typeState: cacheGetters.getTypes(),

            scanLabel: 'cliquez pour scanner',
            loading: { getItem: true },
            form:{ 
                msd_id : 0, msd_uid: '', msd_sta_id: 'GEN01', 
                msd_name: '', msd_code: '', msd_registrationnumber: '', 
                msd_description: '', msd_customfields: {}, 
                msd_usr_id_created: 0, msd_created_at: null, msd_updated_at: null
            },
            statusList: this.getCacheState('GEN'),
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            templatePagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            templateItems: [],
            templateEditId: 0,
            templateHeaders: [
                { text: 'Nom', align: 'left', value: 'tla_name'},
                { text: 'Type', align: 'left', value: 'tla_typ_id'},
                { text: 'Action', align: 'right', value: 'tla_id'},
            ],
            modelPagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            modelEditId: 0,
            modelItems: [],
            modelHeaders: [
                { text: 'Modèle', align: 'left', value: 'bmo_code'},
                { text: 'Groupe', align: 'left', value: 'grp_name'},
                { text: 'Energie', align: 'left', value: 'bmo_typ_id_energy'}, //bmo_code,grp_name,typ_name,bra_name
                { text: 'Action', align: 'right', value: 'bmo_id'},
            ],
            isAuthorized: usrGetters.getScopeRight
        }
    },
    computed: {
        validate () {
            if( !this.saveProgress && this.form.msd_name.length > 0 ){
                return true
            } else {
                return false
            }
        },
    },
    beforeMount: function(){
        if( parseInt(this.$route.params.msd_id) > 0 ){
            //this.getItem()
            this.form.msd_id = this.$route.params.msd_id
            this.getTemplateData()
            this.getModels()
        }

    },
    mounted:function (){},
    methods: {
        async getTemplateData(){
            await this.getItem()
            await this.getTemplate()
        },
        nameControl(){
            if( !this.form.msd_name.length > 0 ){
                this.form.msd_name = this.form.msd_code
            }
        },
        getTemplate(){
            return new Promise( (resolve, reject) => {
                this.templateItems = []
                this.$http.get( '/templatesaction/?per_page=false&tla_msd_id=' + this.form.msd_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.templateItems = response.data.data
                        resolve('resolved')
                    } else {
                        resolve('resolved')
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getModels(){
            return new Promise( (resolve, reject) => {
                this.modelItems = []
                this.$http.get( '/brandmodels/?per_page=false&extended=withbrand,withgroup,withenergy&bmo_msd_id=' + this.form.msd_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.modelItems = response.data.data
                        resolve('resolved')
                    } else {
                        resolve('resolved')
                    }
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        freeModel(bmo_id){
            return new Promise( (resolve, reject) => {
                this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette association', { color: 'red', width: 500 }).then((confirm) => {
                    if( confirm ){
                        this.$http.put( '/brandmodels/' + bmo_id, { bmo_msd_id: null } ).then( () => {
                            this.getModels()
                            resolve('resolved')
                        }).catch( (error) => { 
                            reject(error)
                        })
                    }
                })
            })
        },
        getItem() {
            this.$http.get( '/maintenancestandard/' + this.form.msd_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
                this.form.msd_customfields = (!this.form.msd_customfields) ? {} : this.form.msd_customfields
                this.loading.getItem = false
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        saveItem () {
            // Enregistrement du formulaire
            this.saveProgress    = true
            if( parseInt( this.form.msd_id ) > 0 ){
                this.$http.put( '/maintenancestandard/' + this.form.msd_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/maintenancestandard' })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/maintenancestandard/', this.form )
                .then( (response) => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.form.msd_id = response.data.data.msd_id
                    this.getItem()
                    this.saveProgress = false
                    this.$router.push({ path: '/maintenancestandard/' + response.data.data.msd_id })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression de cette fiche', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.$http.delete( '/equipments/' + this.form.equ_id ).then( () => {
                        this.toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée !' })
                        this.$router.push({ path: '/equipments' })
                    })
                }
            })
        },
        switchModal(name, mode, refresh){
            name    = name || ''
            mode    = mode || 0
            refresh = refresh || false
            //refresh = refresh || false
            this.modalType = name
            if( mode && name.length > 0 ){
                this.modal     = mode
            } else if( !mode ){
                this.modal     = mode
            }
            if( !mode && name == 'template' && refresh ){
                this.getTemplate()
            }
            if( !mode && name == 'addmodel' && refresh ){
                this.getModels()
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
    },
    components: {
        'edit-template' : editTemplate,
        'edit-addmodel' : editAddModelTemplate,
        //'auto-multi': autoCompMulti
    }
}
</script>