import Vue from "vue";
import { cacheData } from "../store/cache.module"
import { scopeCredential } from "../scopeCredential"

export const usrState = Vue.observable({
  role : '',
  genStates: []
})

export const usrGetters = {
  genState: () => {
    return usrState.genStates
  },
  getRole: () => {
    return usrState.role
  },
  getScopeRight: (scope, item) => {
    if( scopeCredential[scope] !== undefined && scopeCredential[scope][item] !== undefined ){
      if( scopeCredential[scope][item].indexOf( cacheData.user.usr_role ) > -1 || scopeCredential[scope][item].indexOf( 'AUTHORIZED' ) > -1 ){
        return true
      } else {
        return false
      }
    } else if( scopeCredential['Generic'][item].indexOf( cacheData.user.usr_role ) > -1 || scopeCredential['Generic'][item].indexOf( 'AUTHORIZED' ) > -1 ){
      return true
    } else {
      return false
    }
  },
  getRolesLabels: (role) => {
    role = role || undefined

    const roleLabels = {
      'BASIC_USER'          : 'Utilisateur (aucun droit)' ,
      'ADMIN_USER'          : 'Administrateur' ,
      'SUPER_USER'          : 'Superviseur' ,
      'WORKSHOP_SUPERVISOR' : 'Superviseur Atelier' ,
      'WORKSHOP_LEADER'     : 'Chef Atelier' ,
      'MECHANIC'            : 'Mécanicien',
      'WORK_SUPERVISOR'     : 'Conducteur de travaux',
      'AGENT'               : 'Agent' ,
      'INTER_USER'          : 'Utilisateur interne',
      'EXTERN_USER'         : 'Utilisateur externe' ,
      'PARTNER_USER'        : 'Utilisateur partenaire' ,
    }

    if( role !== undefined && roleLabels[role] !== undefined ){
      return roleLabels[role]
    } else {
      return roleLabels
    }
  }
}

export const usrActions = {

}

export const usrMutations = {
  setGenStates( genStates ) {
    usrState.genStates = genStates;
  },

};