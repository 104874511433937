<template>
<v-card color="grey lighten-4" min-width="350px" flat >
    <v-toolbar color="primary" dark class="elevation-1" height="50px">
        <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
        <v-toolbar-title>Ajouter un modele</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-6">

    <auto-multi :title="'Rechercher un modèle'" :ressource="'brandmodels'"
        :listObject.sync="listItem" :listParent.sync="listItemSource" 
        :fields="{ 
            index: 'bmo_id', text:'bmo_code,grp_name,typ_name,bra_name', autofocus: true,
            search:'bmo_code,grp_name', preprendIcon:'mdi-table-merge-cells', multiSelect:false, params:'extended=withbrand,withgroup,withenergy' 
        }"
        class="ml-2 mr-2">>
    </auto-multi> <!-- exclude=bmo_msd_id&bmo_msd_id=NULL, + msdId-->

    </v-card-text>
    <!-- <div class="text-right"><v-btn color="success" @click.native="saveForm()">Enregistrer</v-btn></div> -->
</v-card>

</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
import autoCompMulti    from './Autocomplete_multi.vue'

export default {
    name: 'site_edit',
    props: ['toast', 'msdId', 'switchModal'], 
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            locDateFormat: dateLocale, // Format de date
            listItem: [],
            listItemSource: [],
        }
    },
    beforeMount: function(){
        //this.getModelr()
    },
    mounted: function (){ },
    watch: {
        listItem (val) {
            if(val > 0){
                this.saveItem()
            }
        }
    },
    methods: {
        getModel(){

        },
        saveItem(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/brandmodels/' + this.listItem, { 'bmo_msd_id': this.msdId } ).then( () => {
                    this.switchModal('addmodel', 0, 1)
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>