<template>
<v-container fluid>
<v-layout wrap>
    <!-- <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="green" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export de l'activité</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <DatePicker label="Date" :objectForm="activity" fieldName='date'></DatePicker>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex> -->
    <v-flex xs6 style="max-width:100%">
        <v-card class="elevation-1 ma-2">
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title><v-icon>mdi-file-excel</v-icon>Export matériel</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-download</v-icon>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" :disabled="loading.getItem" @click="downloadEquipment()">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Telecharger</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { equObj }  from '../mixins/equ_obj.js'
//import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'site_report',
    //components: { DatePicker },
    props: ['toast'], 
    mixins: [ equObj ],
    data: function () {
        return {
            locDateFormat: dateLocale, // Format de date
            loading: { getItem: false },
            activity: { date: '' },
            state : cacheGetters.getTypesByTag('EVE', 'PRO'),
        }
    },
    mounted:function (){
       
    },
    methods: {
        downloadReport(report, option){
            this.loading.getItem = true
            let searchCriteria = this.getEquipmentFilter()
            for(let prop in option){
                if( option[prop] !== null && option[prop] !== undefined ){
                    searchCriteria += '&' + prop + '=' + option[prop]
                }
            }
            this.$http.customRequest({ method: 'get', url: '/report/' + report + '/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
        downloadEquipment(){
            this.loading.getItem = true
            let searchCriteria = '' //this.getEquipmentFilter()
            this.$http.customRequest({ method: 'get', url: '/report/equipments/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getItem = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getItem = false
            })
        },
    }
}
</script>